import React, { Component } from 'react';

function ListItem(props) {
    return <li style={{padding: "5px"}}>{ props.name }</li>;
  }

class ListElement extends Component {

    render() { 
        const listArray = this.props.listItems;

        return (
            <>
            <ul>
                {listArray.map((item) => <ListItem key={item} name={item} />)}
            </ul>
            </>


        );
    }
}
 
export default ListElement;