import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import emailjs from 'emailjs-com';


emailjs.init('NhO4H6F2wj6uOmNAa');
document.body.style = 'background: #f9f9f9';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
