import React from 'react';
import {FaBars} from 'react-icons/fa';  
import {Nav,NavbarContainer,NavLogo,
MobileIcon,NavMenu,NavItem,NavLinks
,NavBtn,NavBtnLink, Img,ImgWrap} from './NavbarElements';

const Navbar = ({toggle}) => {
    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogo to='/'>
                    <ImgWrap>
                        <Img src='/GITLogo.png' alt='GIT Logo'/>
                    </ImgWrap>
                    </NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars color={"black"}/>
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="/about">
                                About
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/services">
                                Services
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/projects">
                                Projects
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/faq">
                                FAQ
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="/contactus">
                            Contact Us
                        </NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </>
    );

};

export default Navbar;