
import styled from 'styled-components';



export const VideoBgInner = styled.video`
    width: 100%;
    height: 100%;s
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
    //filter: brightness(60%);

`;