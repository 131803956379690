

export const AboutGeneral = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'ABOUT',
    headline: 
    'GIT has proven experience with design, preconstruction, and construction-level geotechnical engineering services. Quality is our priority.',
    description: 
    'Our highly qualified geotechnical experts evaluate subsurface conditions and produce critically important analytical information that should be factored into the design of a project before construction of a foundation, slope grading or other ground modifications begin. GIT provides cost-effective geotechnical services for projects that encompass residential subdivisions, high-rise office buildings, public and private schools and universities, hospitals and other medical facilities, highways, bridges, dams and levees.',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/About/AboutGeneral.svg').default,
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    long: true
}
export const HeroInfo = {
    id: 'HeroInfo',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'GIT Corporation',
    headline: 
    'GIT is a Geotechnical Engineering and Construction Materials Testing Firm.',
    description: 
    'We are based in the Washington Metropolitan Area (Maryland, District of Columbia (DC) and Virginia. ',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/HomePic.svg').default,
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    long: true
}

export const AboutDrillRig = {
    id: 'AboutDrillRig',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 
    'DRILL RIGS',
    headline: 
    'GIT has the capability of providing drilling services for residential and commercial projects',
    description: 
    'GIT uses different size rigs for different kinds of projects. Our portable rigs are very useful on any types of site conditions. In addition, as a geotechnical engineers, we can also have a capability to provide a more accurate field boring logs per ASTM D2488 which will make our drilling service preferable.',
    buttonLabel: 'Button Label Change',
    imgStart: true,
    img: require('../../images/About/DrillRig.jpeg'),
    alt: 'IMG CAR',
    dark: false,
    primary: false,
    darkText: false,
    long: true
}

export const AboutLab = {
    id: 'AboutLab',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'GEOTECHINCAL LABORATORY',
    headline: 
    'GIT provides a wide range of materials testing and inspections with soil, concrete, masonry, and asphalt testing technology.',
    description: 
    'Inspections are performed on a wide variety of construction processes and materials, to confirm compliance with applicable codes and/or project specifications. Each of our staff is cross-trained and certified in multiple areas of construction inspection. Our laboratory can perform a variety of ASTM and AASHTO test methods.',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/About/Laboratory.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    long: true
}

export const AboutInfiltration = {
    id: 'AboutInfiltration',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 
    'IN-SITU INFILTRATION TESTING',
    headline: 
    'Field and laboratory testing of soils, construction materials, and groundwater often complement each other',
    description: 
    ' In a complete testing program, we provide infiltration and percolation tests for the Storm Water Management (SWM) projects per local requirements. Since we have extensive experience locally, we are very familiar to the local testing requirements methods and specifications. All aspects of the infiltration work together to assure accurate and adequate knowledge of the materials used during design, construction, and evaluation of potential subsoil and groundwater contamination problems.',
    buttonLabel: 'Button Label Change',
    imgStart: true,
    img: require('../../images/About/insitsu.jpeg'),
    alt: 'IMG CAR',
    dark: false,
    primary: false,
    darkText: false,
    long: true
}

export const AboutAnalysis = {
    id: 'AboutAnalysis',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'ENGINEERING ANALYSIS AND REPORT',
    headline: 
    'The field and laboratory data, together with our engineering recommendations, will be presented in an engineering report.',
    description: 
    'The report will address the specific project design, construction, and quality control requirements.',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/About/AnalysisAndReporting.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    long: true
}

export const AboutAccreditation = {
    id: 'AboutAccreditation',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 
    'ACCREDITATION',
    headline: 
    'GIT Laboratory has been accredited by the American Association of State Highway and Transportation Officials (AASHTO) Accreditation Program. GIT Laboratory participates in Cement and Concrete Reference Laboratory (CCRL) inspections and proficiency sample programs.',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: true,
    img: require('../../images/About/Accreditation.jpeg'),
    alt: 'IMG CAR',
    dark: false,
    primary: false,
    darkText: false,
    long: true
}

export const AboutCerts = {
    id: 'AboutCerts',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'CERTIFICATIONS',
    headline: 
    'Our company holds the following certifications: MBE and DBE. Our technicians are also certified by American Concrete Institute (ACI). All our engineers are licensed by state as a Professional Engineer (PE)',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/About/Certifications.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    long: true
}




export const ServiceGeneral = {
    id: 'ServiceGeneral',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'SERVICES',
    headline: 
    'GIT Provides a variety of services including:',
    description: 
    'Geotechnical Investigation Services, Construction Inspection and Testing, Laboratory Testing',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/Services/ServicesGeneral.svg').default,
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true
}




export const ProjectGeneral = {
    id: 'ProjectGeneral',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'PROJECTS',
    headline: 
    'We have worked with many sectors and industries including:',
    description: 
    'Residential, commercial, educational, Medical/Healthcare, Office Buildings, Transportation/Infrastructure, and more',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/About/AboutGeneral.svg').default,
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    long: true
}


export const ProjectResidential = {
    id: 'ProjectResidential',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'RESIDENTIAL',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/Projects/Residential.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    isHeaderCentered: true,
    long: true
}

export const ProjectCommercial = {
    id: 'ProjectCommercial',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'COMMERCIAL',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/Projects/Commercial.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    isHeaderCentered: true,
    long: true
}

export const ProjectMedical = {
    id: 'ProjectMedical',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'MEDICAL/HEALTHCARE',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/Projects/Medical.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    isHeaderCentered: true,
    long: true
}

export const ProjectTransport = {
    id: 'ProjectTransport',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'TRANSPORTATION AND INFRASTRUCTURE',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/Projects/Transportation.jpeg'),
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true,
    isHeaderCentered: true,
    long: true
}


export const FAQGeneral = {
    id: 'FAQGeneral',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'FAQ',
    headline: 
    'Ask us your questions!',
    description: 
    'Here are some frequently asked questions we have received in the past. Please contact us for any further questions/concerns.',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    img: require('../../images/FAQ/Questions.svg').default,
    alt: 'IMG CAR',
    dark: true,
    primary: true,
    darkText: true
}