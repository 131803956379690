

export const ServicesGeoInvestigation = {
    id: 'ServicesGeoInvestigation',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 
    'GEOTECHNICAL INVESTIGATION SERVICES',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: false,
    primary: false,
    darkText: true,
    listItems: ["Subsurface Investigations", "Geotechnical Test Borings", "Rock Coring", "Test Pits", "Foundation Assessments", "Laboratory Testing", "Infiltration & Percolation Testing", "Soil Typing Reports", "Asphalt & Concrete Coring", "Dynamic Cone Penetrometer Testing", "Seismic Site Classification", "Bearing Capacity and Settlement Analysis", "Topsoil Nutrient Analysis", "Flexible/Rigid Pavement Evaluation/Design", "Slope Stability Analyses"]
}

export const ServiceInspectionAndTesting = {
    id: 'ServiceInspectionAndTesting',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'CONSTRUCTION INSPECTION AND TESTING',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: true,
    primary: false,
    darkText: true,
    listItems: ["Soil Compaction Testing (Nuclear Gauge Method)", "Footing Inspection", "Backfill Inspection", "Slab Inspection", "Concrete Testing", "Foundation Consulting", "Soil Modification", "Reinforcing Steel/Rebar Inspection", "Construction Materials Testing", "Underpinning Soil Evaluation", "Asphalt Density", "Fireproofing Inspection"]
}


export const ServiceSoil = {
    id: 'ServiceSoil',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 
    'SOIL',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: false,
    primary: false,
    darkText: true,
    listItems: ["Partical Size Distribution/Grain Size Analysis, ASTM D422", "Hydrometer/USDA/USCS Method, ASTM D422", "Atterberg Limits, ASTM D4318", "Standard/Modified Proctor ASTM D698/D1557", "California Bearing Ratio (CBR), ASTM D1883)", "Moisture Content ASTM D2216", "Unconfined Compression ASTM D2166", "ph (ASTM D4972)", "Organic Content (ASTM D2974)", "Soluble Salts", "Calcium", "Magnesium", "Phosphorus", "Potassium", "Cation Exchange Capacity", "Total Kjeldahl Nitrogen", "Hydraulic Conductivity/Permeability"]
}


export const ServiceConcrete = {
    id: 'ServiceConcrete',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'CONCRETE',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: true,
    primary: false,
    darkText: true,
    listItems: ["Compressive Strength", "Temperature", "Slump", "Air Content", "Density/Unit Weight", "Concrete Coring", "Hammer Test"]
}

export const ServiceAsphalt = {
    id: 'ServiceAsphalt',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 
    'ASPHALT',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: false,
    primary: false,
    darkText: true,
    listItems: ["Coring", "Density/Specific Gravity", "Asphalt Compaction Testing (Nuclear Gauge Method)"]
}

export const ServiceAggregate = {
    id: 'ServiceAggregate',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'AGGREGATE',
    headline: 
    '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: true,
    primary: false,
    darkText: true,
    listItems: ["Gradation fine and coarse aggregate (ASTM C136)", "Specific Gravity/Absorption (ASTM C127, C128)", "Soundness (ASTM C88)", "Dry Rodded Unit Weight (ASTM C29)", "Organic Impurities (ASTM C40)"]
}


export const ProjectAdditional = {
    id: 'ProjectAdditional',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 
    'ADDITIONALLY',
    headline: '',
    description: 
    '',
    buttonLabel: 'Button Label Change',
    imgStart: false,
    dark: true,
    primary: false,
    darkText: true,
    listItems: ["Educational","Office Buildings", "Industrial/Wastewater",  "Pipelines/Utility/Energy", "Sport/Recreational", "Religious/Cultural", "Municipal/Governmental/Institutional",  "Parking Garages"]
}