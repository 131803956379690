import styled from 'styled-components';
import {BsPlusLg} from 'react-icons/bs';


export const  FAQContainer = styled.div`

    background: #f9f9f9;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;

export const FAQWrapper = styled.div`

    display: flex;
    height: 100%;

    max-width: 1000px;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
`;

export const FAQRow = styled.div`
    cursor: pointer;
    border-top: 1px solid black;
    padding: 10px;
    flex: 0 0 75px;
`;

export const Question = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 24px;
    color: #010606;
    font-weight: 650;
    display: inline;
    flex: 0 0 75px;

`;

export const Answer = styled.p`
    margin-bottom: 35px;
    font-size: 15px;
    line-height: 24px;
    color: #010606;
    padding-left: 47px;
    transition: 0.3s ease-in-out;
    display: ${({isAnswerExpanded}) => (isAnswerExpanded ? 'inline': 'none')};
    max-width: 900px;

`;

export const PlusButton =  styled(BsPlusLg)`
    margin-right: 20px;
    display: inline;
`;