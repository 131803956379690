import styled from 'styled-components';
import {FaRegQuestionCircle} from 'react-icons/fa';

export const  ContactContainerTop = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;    
    max-width: 3900px;
    flex-direction: vertical;

`;

export const  ContactContainer = styled.div`

    background: #f9f9f9;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    flex-direction: horizontal;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        padding: 50px 0px;
    }
`;

export const ContactWrapper = styled.div`

    width: 70%;
    padding: 10px 50px 50px 10px;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }

`;
export const ContactInfoWrapper = styled.div`
    width: 25%;
    padding: 10px 50px 50px 10px;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;



export const InputButton = styled.input.attrs({
    type: "button",
    value: "Send",
    className: "ui button"
})`
    border-radius: 50px;
    background #4f8cc9;
    white-space: nowrap;
    padding: 10px 22px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #010606;
    }
`;

export const ToolTip = styled.div`


`;

export const ToolTipText = styled.span`
    visibility: hidden;
    width: 300px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 12px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: -5px;
    left: 105%;
    
    &:after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 100%; /* To the left of the tooltip */
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent black transparent transparent;
    }
`;

export const Question = styled.div`
    position: relative;
    display: inline-block;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
    &:hover {
        ${ToolTipText} {
            visibility: visible;
        }
    }
`;

export const QuestionImg = styled(FaRegQuestionCircle)`
    margin-right: 5px;
`;

export const HeaderTop = styled.h1`
    margin-bottom: 10px;
`;

export const ContactInfoText = styled.p`
    font-size: 15px;
    margin: 5px;
    font-weight: ${({isBold})=> (isBold ? 'bold' : 'normal')};

`;

export const SuccessMessage = styled.div`
    padding: 10px;
    background: #89FF95;
    color: #0C2709;
    border-radius: 5px;
    margin-bottom: 5px;
    display: ${({isSuccess})=> (isSuccess ? 'block' : 'none')};

`;

export const FormWrapper = styled.div`

`;


export const BreakWrapper = styled.div`
    position: relative;
    width: 5%;
    height: 600px;

    @media screen and (max-width: 1000px) {
        display: none;
    }
`;

export const Line = styled.div`
    position: absolute;
    left: 49%;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #ccc;
    z-index: 1;
    background-image: radial-gradient(#CCC, #FFF), radial-gradient(#CCC, #FFF);
`;

export const LineWordWrapper = styled.div`
    text-align: center;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -12px;
    z-index: 2;
`;
