import React,{useState} from 'react';
//import Video from '../../videos/GITLogo.mp4';
import {HeroContainer, HeroBg,
HeroContent,HeroH1,HeroBtnWrapper,HeroP,ArrowForward,ArrowRight} from './HeroElements'
import {Button} from '../ButtonElements';
import VideoElement from '../VideoElement';
import Video1 from '../../videos/video1.mp4';
import Video2 from '../../videos/video2.mp4';
import Video3 from '../../videos/video3.mp4';

const HeroSection = () => {
    const [hover,setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    var myVids = [
        Video1,Video2,Video3
    ];

    return (
        <HeroContainer id="home">
            <HeroBg>
                <VideoElement videos={myVids} ></VideoElement>
            </HeroBg>
            <HeroContent>
                <HeroH1>
                  Geotechnical Inspection and Testing  
                </HeroH1>
                <HeroP>
                    GIT is a Geotechnical Engineering and Construction Materials Testing firm based in the Washington Metropolitan Area.
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="/contactus" onMouseEnter={onHover} onMouseLeave={onHover}
                    primary='true' dark='true'>
                        Contact Us {hover ? <ArrowForward /> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    );
};

export default HeroSection;