import React, { Component } from 'react';
import { HeaderText, HeaderText2, HomeLink, NotFoundContainer, Img,ImgWrap, NotFoundWrapper, NotFoundContainerTop } from './NotFoundElements';


class NotFound extends Component {
    render() { 
        return (
            <>
            <NotFoundContainerTop>
                <NotFoundWrapper>
                    <NotFoundContainer isAlignBottom={false} isMarginTop={false}>
                        <ImgWrap  maxWidth={'300px'} maxHeight={'300px'} isMarginBot={false} >
                            <Img src={require('../../images/404.png')} alt='404 image'/>
                        </ImgWrap>
                        <HeaderText>The page you requested could not be found. </HeaderText>
                        <HomeLink to='/'>
                            <HeaderText2> Click here to go back home.</HeaderText2>
                        </HomeLink> 
                    </NotFoundContainer>

                    <NotFoundContainer isAlignBottom={true} isMarginTop={true}>
                        <ImgWrap maxWidth={'800px'} maxHeight={'800px'} isMarginBot={true}>
                            <Img src={require('../../images/lost.svg').default} alt='404 Page Image'/>
                        </ImgWrap>
                    </NotFoundContainer>
                </NotFoundWrapper>
            </NotFoundContainerTop>
            </>
            
        );
    }
}
 
export default NotFound;