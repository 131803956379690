import React from "react";
import { VideoBgInner } from "./VideoElements";



export default class Video extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      index: 0,
      src: this.props.videos[0]
    };
  }

  componentDidMount() {
    let video = this.myRef.current;
    video.addEventListener("ended", e => {
      if (this.state.index < this.props.videos.length - 1) {
        let nextIndex = this.state.index + 1;
        this.setState({
          index: nextIndex,
          src: this.props.videos[nextIndex]
        });
      } else {
        let nextIndex = 0;
        this.setState({
          index: nextIndex,
          src: this.props.videos[nextIndex]
        });
      }
    });
    video.play();
  }
  componentDidUpdate(prevProps, prevState) {
    let video = this.myRef.current;
    let playPromise = video.play();
    if (playPromise !== undefined) {
      playPromise.then(function() {
        // Automatic playback started!
      }).catch(function(error) {
        // Automatic playback failed.
        // Show a UI element to let the user manually start playback.
      });
    }
  }
  render() {
    return (
        <VideoBgInner ref={this.myRef} autoPlay  muted src={this.state.src} type='video/mp4'/>
    );
  }
}