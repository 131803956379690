import React, { Component } from 'react';
import {
    FaIcon,
    FooterContainer,
    FooterSections,
    FooterWrapper,  
    IconSection, 
    IconWrapper, 
    LineText,
    LnIcon} from './FooterElements';

const currYear = new Date().getFullYear();

class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <>
            <FooterWrapper>
                <FooterContainer>
                    

                    <FooterSections>
                        <small>&copy; Copyright {currYear}, GIT Corporation - All Rights Reserved</small>

                        <LineText isBold={true}>The Wise and Foolish Builders</LineText> 
                        <LineText isBold={false}>
                        <sup>24</sup> Jesus said “Therefore everyone 
                        who hears these words of mine
                         and puts them into practice is
                          like a wise man who built his 
                          house on the rock. <sup>25</sup> The rain came down,
                           the streams rose, and the winds blew and 
                           beat against that house; yet it did not fall, 
                           because it had its foundation on the rock. <sup>26 </sup> 
                           But everyone who hears these words of mine and does 
                           not put them into practice is like a foolish man who 
                           built his house on sand. The rain came down, the streams
                            rose, and the winds blew and beat against that house, 
                            and it fell with a great crash.”</LineText> 
                             <LineText>Matthew 7:24-27</LineText> 

                    </FooterSections>                    
                </FooterContainer>
                <IconSection>
                        <IconWrapper to='https://www.facebook.com/GIT-Engineering-102783315782377'>
                            <FaIcon></FaIcon>
                        </IconWrapper>

                        <IconWrapper to='https://www.linkedin.com/company/git-corp-engineering'>
                            <LnIcon></LnIcon>
                        </IconWrapper>
                </IconSection>
            </FooterWrapper>
            
            </>

        );
    }
}
 
export default Footer;