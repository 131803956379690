import React, {useState} from 'react';
import {Routes,Route} from 'react-router-dom';

import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import BulletSection from '../components/BulletSection';
import FAQSection from '../components/FAQSection';
import ContactForm from '../components/ContactForm';
import Footer from '../components/FooterSection';
import NotFound from '../components/NotFound';

import { AboutGeneral,HeroInfo,
    AboutAccreditation,
    AboutAnalysis,
    AboutCerts,
    AboutDrillRig,
    AboutInfiltration,
    AboutLab,ServiceGeneral,ProjectGeneral,
    ProjectResidential, ProjectCommercial, ProjectMedical, ProjectTransport, FAQGeneral } from '../components/InfoSection/Data';

import { ProjectAdditional, ServiceAggregate, ServiceAsphalt, ServiceConcrete, ServiceInspectionAndTesting, ServicesGeoInvestigation, ServiceSoil,
    } from '../components/BulletSection/Data';


const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen (!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle}/>

            <Routes>
                <Route exact path='/' element={
                    <>   
                    <HeroSection /> 
                    <InfoSection {...HeroInfo}/>
                    </>} />
                <Route path='/contactus' 
                    element={
                    <>
                    <ContactForm/>
                    
                    </>} exact/>
                <Route path='/about' 
                    element={
                    <>
                    <InfoSection {...AboutGeneral}/>
                    <InfoSection {...AboutDrillRig}/>
                    <InfoSection {...AboutLab}/>
                    <InfoSection {...AboutInfiltration}/>
                    <InfoSection {...AboutAnalysis}/>
                    <InfoSection {...AboutAccreditation}/>
                    <InfoSection {...AboutCerts}/>
                    
                    </>} exact/>
                <Route path='/services' 
                    element={
                    <>
                    <InfoSection {...ServiceGeneral}/>
                    <BulletSection {...ServicesGeoInvestigation}/>
                    <BulletSection {...ServiceInspectionAndTesting}/>
                    <BulletSection {...ServiceSoil}/>
                    <BulletSection {...ServiceConcrete}/>
                    <BulletSection {...ServiceAsphalt}/>
                    <BulletSection {...ServiceAggregate}/>


                        
                    </>} exact/>
                <Route path='/projects' 
                    element={
                    <>
                    <InfoSection {...ProjectGeneral}/>
                    <InfoSection {...ProjectResidential}/>
                    <InfoSection {...ProjectCommercial}/>
                    <InfoSection {...ProjectMedical}/>
                    <InfoSection {...ProjectTransport}/>
                    <BulletSection {...ProjectAdditional}/>

                    
                    </>} exact/>                
                    <Route path='/faq' 
                    element={
                    <>
                    <InfoSection {...FAQGeneral}/>
                    <FAQSection/>
                    
                    </>} exact/>   
                <Route path='*' element={<NotFound/>} />

            </Routes>
            <Footer />
        </>
    );
}

export default Home;