import styled from 'styled-components';
import {BsFacebook,BsLinkedin} from 'react-icons/bs';

export const FooterWrapper = styled.footer`
    background: #343030;
    color: #fff;
    height: auto;
    /*margin-top: -80px;*/
    display: flex;
    justify-content: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px)
    {
        transition: 0.8s all ease;
    }

`;

export const HorizontalLine = styled.hr`
    size: 2px;
`;

export const FooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    padding: 10px 10px;
    max-width: 1100px;
    

`;

export const FooterSections = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    
`;

export const IconSection = styled.div`
    padding: 5px;
    display: flex;
    height: 30px;
`;

export const IconWrapper = styled.a.attrs( 
    ({ to }) => ({ href:  to, target : "_blank"}) )`
    height: 100%;
    width: auto;
    color: white;
    &:hover {
        transform: scale(0.90);
        cursor: pointer;
    }

`;

export const LineText = styled.h6`
    padding: 5px;
    font-weight: ${({isBold})=> (isBold ? 'bold' : 'normal')};
    
`;

export const FaIcon = styled(BsFacebook)`
    flex: 50%;
    margin: 5px;
`;

export const LnIcon = styled(BsLinkedin)`

    flex: 50%;
    margin: 5px;

`;