
import React, { Component, useState } from 'react';
import { FAQContainer,FAQWrapper, Question,Answer, FAQRow,PlusButton } from './FAQElements';




function FAQItem(props) {

    const [isAnswerExpanded,expandAnswer] = useState(false);

    return <>
    <FAQRow onClick={() => expandAnswer(!isAnswerExpanded)}> 
        <PlusButton/>
        <Question>{props.question}</Question>
    </FAQRow> 
    <Answer isAnswerExpanded={isAnswerExpanded}>{props.answer}</Answer>

    </>;
  }


class FAQList extends Component {

    render() { 
        const QandA = [
            {
                question: "How do Geotechnical Investigations affect a Project?",
                answer:"Geotechnical investigations reduce an owner's risk by getting data upfront to assist in design plans and identify potential performance issues before construction begins. A full geotechnical investigation helps geotechnical engineers understand how soil will react to compaction, water saturation, seismic activity, permafrost, and more. This information is then passed on to the rest of the design team (especially structural engineers) to help with all other aspects of the project design."
            },

            {
                question: "What to expect from a detailed Geotechnical Investigation?",
                answer:"The geotechnical report is used to tell you the site conditions and design and construction recommendations to the roadway design, bridge design, and construction personnel. Engineers need these reports to conduct an adequate review of geotechnical related features, like earthwork and foundations"
            },
            {
                question: "Why is testing important in geotechnical engineering?",
                answer:"Laboratory testing is an integral part of geotechnical engineering research and practice. A well planned and properly executed laboratory testing program will provide soil and/or rock properties needed to perform geotechnical analyses and develop geotechnical models."
            },
            {
                question: "What is the importance of geotechnical site investigation?",
                answer:"Geotechnical investigations have become an essential component of every construction to ensure safety of human beings and materials. It includes a detailed investigation of the soil to determine the soil strength, composition, water content, and other important soil characteristics."
            },
            {
                question: "What is soil analysis for construction?",
                answer:"Soil testing is when a geotechnical engineer visits your site to acquire and test soil samples. Tests will reveal the characteristics, nature, and reactivity of soil. Soil reactivity accounts for changes in ground composition under varying conditions."
            },
            {
                question: "Do I Need a Geotechnical Report?",
                answer:"If you’re planning a new construction project, you’ll probably need a geotechnical report — or, at minimum, getting a proper geotechnical report has the potential to reduce risk and save you money and time."
            },
            {
                question: "Why do I need an infiltration testing report?",
                answer:"Infiltration tests estimate the rate at which runoff will infiltrate, or pass through, native soil. An infiltration test, in essence, involves digging a hole, pouring in water, and measuring the drop in water level over time."
            },
            {
                question: "What are the methods of infiltration?",
                answer:"Infiltration rate usually is determined from field data. Many different methods and types of equipment have been used for measuring infiltration rate, but the principal methods are constant head and falling head methods"
            },
            {
                question: "What is the difference between infiltration rate and percolation rate?",
                answer:"Infiltration rate in the soil is a measure of the rate at which soil is able to absorb rainfall or irrigation. It is measured in inches per hour or millimeters per hour. Percolation rate is defined as the rate at which fluid is filtered through porous materials."
            },
            {
                question: "Pay now or Pay Later?",
                answer:"The cost associated with a geotechnical investigation is comparably low with respect to the total cost of construction.  However, a well-designed investigation can significantly reduce the risk of cost overruns due to unforeseen conditions during construction and for the lifespan of the structure. If you need quality subsurface investigation, construction materials testing, or professional foundation planning, get in touch with our geotechnical engineering professionals for expert guidance and simplified project success today!"
            },
        ];

        return (
            <>
            <FAQContainer >
                <FAQWrapper>
                   {QandA.map((item) => <FAQItem key={item.question}  question={item.question} answer={item.answer} />)} 
                </FAQWrapper>
            </FAQContainer>
            </>


        );
    }
}
 
export default FAQList;