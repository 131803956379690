import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './pages';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop></ScrollToTop>
      <Routes>
        <Route path='*' element={<Home/>}>
          <Route path='about' element={<Home/>}/>
          <Route path='services' element={<Home/>}/>
          <Route path='projects' element={<Home/>}/>
          <Route path='faq' element={<Home/>}/>
          <Route path='contactus' element={<Home/>}/>
        </Route>


      </Routes>
    </Router>
  );
}

export default App;
