import React from 'react';
import {Button} from '../ButtonElements';
import ListElement from '../ListElements';


import { InfoContainer, 
InfoWrapper,
InfoRow,
Column1,
Column2,
TextWrapper,
TopLine,
Heading,
Subtitle,
BtnWrap,
ListWrapper
} from './BulletElements';


const BulletSection = ({
    lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headline,
    darkText,
    description,
    buttonLabel,
    primary,
    dark,
    dark2,
    listItems}) => {

    return (
        <>
        <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                    <Column1>
                        <TextWrapper>
                            <TopLine>{topLine}</TopLine>
                            <Heading lightText={lightText}>{headline}</Heading>
                            <Subtitle darkText={darkText}>{description}</Subtitle>
                            <BtnWrap>
                                <Button to="home"
                                duration={500}
                                exact="true"
                                primary={primary ? 1 : 0}
                                dark={dark ? 1:0}
                                dark2={dark2 ? 1:0}
                                >{buttonLabel}</Button>
                            </BtnWrap>
                        </TextWrapper>
                    </Column1>
                    <Column2>
                        <ListWrapper darkText={darkText}>
                            <ListElement listItems={listItems}/>
                        </ListWrapper>
                    </Column2>

                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
        </>
    )
}

export default BulletSection;