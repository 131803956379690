import React from 'react';
import emailjs from 'emailjs-com';
import toast, {Toaster } from 'react-hot-toast';
import { ContactContainer, ContactContainerTop,ContactInfoWrapper, 
  ContactWrapper, InputButton, Question, QuestionImg, 
  ToolTipText, HeaderTop, ContactInfoText, FormWrapper, 
  SuccessMessage, Line, BreakWrapper, LineWordWrapper} from './ContactElements';

export default class ContactForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleSuccess = this.handleSuccess.bind(this);
      this.handleFailure = this.handleFailure.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.sendMessage = this.sendMessage.bind(this);

      this.state = {
        from_name: "",
        from_email: "",
        message: "",
        from_phone: "",
        isSuccess: false
      };
    }
    handleInputChange(event) {
      const target = event.target;
      const name = target.name;
      const value = target.value;
      this.setState({ [name]: value });
      this.setState({isSuccess: false});
    }

    handleSuccess = () => {
      this.setState({
        from_name: "",
        from_email: "",
        message: "",
        from_phone: "",
        isSuccess: true
      });
    }
    handleFailure = () => {
      this.setState({
        from_name: "",
        from_email: "",
        message: "",
        from_phone: "",
        isSuccess: false

      });
    }
    
    validateMail(){
      var from_name = document.getElementById("from_name");
      var from_email = document.getElementById("from_email");
      var from_phone = document.getElementById("from_phone");
      var message = document.getElementById("message");

      if(from_name.value === '' || from_email.value === '' || from_phone.value === '' || message.value === '')
      {
          alert("Please ensure all fields are filled before sending a message.");
          return false;
      }

      return true;
    }

    sendMessage(event) {
      var formElement = document.getElementById("form");
      event.preventDefault();
      if (!this.validateMail()) {
        return;
      }
      var that= this;
      emailjs
        .sendForm("default_service", "template_rtjuuk8", formElement)
        .then(
          function(response) {
            toast.success("Your message has successfully sent!");
            that.handleSuccess();
          },
          function(err) {
            toast.error("Your message was not able to be sent, please try again later.");
            that.handleFailure();

          }
        );
    }

    render() {
        const isSuccess = this.state.isSuccess;
        return (
          <>
          <ContactContainerTop>
          <ContactContainer>
            <ContactWrapper>
              <HeaderTop>Contact Us Today</HeaderTop>

              <SuccessMessage isSuccess={isSuccess}>
                <ContactInfoText isBold={true}>
                  Message Successfully Sent!
                </ContactInfoText>
                <ContactInfoText isBold={false}>
                  Thank you for your interest. We will be in touch soon!
                </ContactInfoText>
              </SuccessMessage>

              <FormWrapper>
                <form
                  className="ui form"
                  id="form"
                  name={this.props.name}
                  method={this.props.method}
                  action={this.props.action}
                >
                  <label htmlFor="from_name" style={{marginLeft: "5px"}}>Name:</label>
                  <textarea
                    id="from_name"
                    name="from_name"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Your name"
                    required
                    value={this.state.from_name}
                    style={{ width: "100%",padding: "5px", margin: "5px", resize: "none" }}
                    rows={1}
                  />
                  <br />
                  <label htmlFor="from_email" style={{marginLeft: "5px"}}>Email:</label>
                  <Question>
                    <QuestionImg></QuestionImg>
                    <ToolTipText>Email will only be used to reply to this message.</ToolTipText>
                  </Question>
                  <textarea
                    id="from_email"
                    name="from_email"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Your email address"
                    required
                    value={this.state.from_email}
                    style={{ width: "100%",padding: "5px", margin: "5px", resize: "none" }}
                    rows={1}
                  />

                  <label htmlFor="from_phone" style={{marginLeft: "5px"}}>Phone:</label>
                  <textarea
                    id="from_phone"
                    name="from_phone"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Your phone"
                    required
                    value={this.state.from_phone}
                    style={{ width: "100%",padding: "5px", margin: "5px", resize: "none" }}
                    rows={1}
                  />
                  <br />
                  <label htmlFor="message" style={{marginLeft: "5px"}}>Message:</label>
                  <textarea
                    id="message"
                    name="message"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Your message"
                    required
                    value={this.state.message}
                    style={{ width: "100%", height: "250px" ,padding: "5px", margin: "5px" , whiteSpace: 'pre-line',resize: "none"}}
                  />
                  <br />
                  <InputButton onClick={this.sendMessage.bind(this)} />
                </form>
              </FormWrapper>
            </ContactWrapper>

            <BreakWrapper>
              <Line>
                <LineWordWrapper/>
              </Line>
            </BreakWrapper>

            <ContactInfoWrapper>
              <HeaderTop>Contact Info</HeaderTop>
              <ContactInfoText isBold={true}>Sentayehu B. Akalu, P.E.</ContactInfoText>
              <ContactInfoText isBold={true}>President/Principal Engineer</ContactInfoText>
              <br/>
              <ContactInfoText isBold={true}>GIT Corporation</ContactInfoText>
              <ContactInfoText isBold={false}>14674-F Southlawn Ln, Rockville, MD 20850 </ContactInfoText>
              <ContactInfoText isBold={false}>Tel: (240) 505-8154 </ContactInfoText>
              <ContactInfoText isBold={false}>sente@git-engineers.com </ContactInfoText>

            </ContactInfoWrapper>

          </ContactContainer>
          </ContactContainerTop>
          
            
            <Toaster/>
          </>
        );
      };

      
    }