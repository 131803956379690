import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';


export const HomeLink = styled(LinkR)`
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    
`;

export const HeaderText = styled.h1`
    width: 100%;
    font-size: 20px;
`;

export const HeaderText2 = styled.h2`
    color: #4f8cc9 ;
    font-size: 15px;

    &:hover {
        color: gray;
    }
`;

export const NotFoundWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 2000px;
`;

export const NotFoundContainer = styled.div`
    background: #f9f9f9;
    text-align: center;
    width: 50%;
    align-self: ${({isAlignBottom})=> (isAlignBottom ? 'flex-end' : 'start')};
    margin-top: ${({isMarginTop})=> (isMarginTop ? '350px' : '0px')};

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-top: 10px;
    }
`;

export const ImgWrap = styled.div`
    max-width: ${({maxWidth}) => maxWidth};
    max-height: ${({maxHeight}) => maxHeight};
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-bottom: ${({isMarginBot})=> (isMarginBot ? '-5px' : '0px')};

    @media screen and (max-width: 768px) {
        margin-bottom: ${({isMarginBot})=> (isMarginBot ? '-5px' : '0px')};
        width: 100%;

    }
`;

export const Img = styled.img`
    width: 100%;
`;

export const  NotFoundContainerTop = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;    
    max-width: 5000px;
    flex-direction: vertical;

`;
